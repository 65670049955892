import React, { useEffect, useRef, useState } from "react";
import { Button, Fab, makeStyles, Typography, Zoom } from "@material-ui/core";

import { ContactDialog, Footer, Header, Map } from "../components";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from "../components/icons";
import {
  corpIntroIllust,
  mobileFrame,
  mobileHome,
  mobileNotice,
  mobileFee,
  mobileVehicle,
  monitorFrame,
  monitorDashboard,
  monitorParkingSpace,
  monitorRealtimeVideo,
  monitorVideoRecord,
  monitorRealtimeInOut,
  monitorInOutRecord,
  monitorVehicle,
  monitorParkingFee,
} from "../assets/small";

const useStyles = makeStyles((theme) => ({
  "@keyframes mobile": {
    "0%": { top: "100%", opacity: 1 },
    "2%": { top: "0%", opacity: 1 },
    "48%": { top: "0%", opacity: 1 },
    "50%": { top: "0%", opacity: 0 },
    "100%": { top: "0%", opacity: 0 },
  },
  "@keyframes monitor": {
    "0%": { opacity: 0 },
    "50%": { opacity: 0 },
    "52%": { opacity: 1 },
    "95%": { opacity: 1 },
    "100%": { opacity: 0 },
  },
  floatingCircle: {
    position: "absolute",
    borderRadius: "50%",
    zIndex: 1,
    transition:
      "10s top cubic-bezier(0.15,1,0,1), 10s left cubic-bezier(0.15,1,0,1)",
    "&#circle0": {
      width: "30vw",
      height: "30vw",
      background: `linear-gradient(135deg, #EFFAFC 30%, #D8F1FD 70%)`,
    },
    "&#circle1": {
      width: "18vw",
      height: "18vw",
      background: `linear-gradient(135deg, #F5E9F7 30%, #FBDDDD 70%)`,
    },
    "&#circle2": {
      width: "7vw",
      height: "7vw",
      background: `#DCE775`,
    },
    "&#circle3": {
      width: "3vw",
      height: "3vw",
      background: `#ffa69e`,
    },
  },
  main: {
    position: "relative",
    overflowY: "scroll",
    height: "100%",
    // NOTE image preloading
    "&:after": {
      // hide images
      position: "absolute",
      width: 0,
      height: 0,
      overflow: "hidden",
      zIndex: -1,
      // load images
      content: [
        mobileHome,
        mobileNotice,
        mobileFee,
        mobileVehicle,
        monitorFrame,
        monitorDashboard,
        monitorParkingSpace,
        monitorRealtimeVideo,
        monitorVideoRecord,
        monitorRealtimeInOut,
        monitorInOutRecord,
        monitorVehicle,
        monitorParkingFee,
      ]
        .map((src) => `url(${src})`)
        .join(" "),
    },
    "& > section": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      overflow: "hidden",
      height: "100vh",
      paddingTop: theme.sizes.Header.mdHeight,
      minHeight: 720 - theme.sizes.Header.mdHeight,
      background: "white",
      textAlign: "center",
      "& > *": { zIndex: 2 },
      "& > .title": {
        fontSize: 32,
        fontWeight: 700,
        lineHeight: "46px",
      },
      // NOTE section1 style
      "&:nth-of-type(1)": {
        "& > .title": {
          marginTop: 32,
          "& > span": {
            color: theme.palette.text.blue,
          },
        },
        "& > .subTitle": {
          margin: "8px 0px 16px",
        },
        "& > .contactButton": {
          height: 40,
          width: 150,
          borderRadius: 20,
          color: "white",
          fontWeight: 400,
          background: "linear-gradient(90deg, #2945FF 0%, #269AE7 100%)",
          "& .MuiButton-endIcon": { margin: "1px 0px 0px" },
          "& svg": { height: 24, width: 24 },
        },
        "& > .demo": {
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          position: "relative",
          height: 462,
          width: "100%",
          "& > .mobile": {
            position: "absolute",
            animationName: "$mobile",
            animation: "8s linear infinite",
            marginTop: 44,
            width: 236,
            height: 428,
            background: `no-repeat center / 100% 100% url(${mobileFrame})`,
            padding: "12px 16px",
            "& > div": {
              borderRadius: 20,
              width: "100%",
              height: "100%",
              background: `no-repeat center / 100% 100% url(${mobileHome})`,
            },
          },
          "& > .monitor": {
            position: "absolute",
            animationName: "$monitor",
            animation: "8s linear infinite",
            marginTop: 88,
            width: 320,
            height: 236,
            padding: "4px 4px 35px",
            background: `no-repeat center / 100% 100% url(${monitorFrame})`,
            "& > div": {
              width: "100%",
              height: "100%",
              background: `no-repeat center / 100% 100% url(${monitorDashboard})`,
            },
          },
        },
      },
      // NOTE section2 style
      "&:nth-of-type(2)": {
        background: "#F6FEFF",
        "& > .paragraph": {
          fontSize: 18,
          marginTop: 40,
          "& > span": {
            display: "inline-block",
            lineHeight: "18px",
            fontWeight: 700,
            background: "#FFF176",
            borderRadius: 4,
            padding: "2px",
          },
        },
        "& > img": {
          width: 320,
          marginTop: 56,
        },
      },
      // NOTE section3 style
      "&:nth-of-type(3)": {
        "& > .subTitle": {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 36,
          marginBottom: 12,
          color: "white",
          background: "#00BCD4",
          height: 34,
          borderRadius: 20,
          width: 120,
          transition: "1s opacity",
        },
        "& > .text": {
          fontSize: 12,
          transition: "1s opacity",
        },
        "& > .demo": {
          display: "flex",
          position: "relative",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          "& > .circles": {
            position: "relative",
            marginTop: -20,
            minWidth: 400,
            height: 400,
            "& > div": {
              borderRadius: "50%",
              position: "absolute",
              background: "linear-gradient(45deg, #4FC3F7, #69F0AE)",
              "&:nth-child(1)": {
                width: "100%",
                height: "100%",
                opacity: 0.1,
              },
              "&:nth-child(2)": {
                top: "5%",
                left: "5%",
                width: "90%",
                height: "90%",
                opacity: 0.15,
              },
              "&:nth-child(3)": {
                top: "10%",
                left: "10%",
                width: "80%",
                height: "80%",
                opacity: 0.2,
              },
              "&:nth-child(4)": {
                top: "15%",
                left: "15%",
                width: "70%",
                height: "70%",
                opacity: 0.3,
              },
            },
          },
          "& > .mobiles": {
            position: "absolute",
            overflowX: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            "& > div": {
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 190,
              height: 346,
              background: `no-repeat center / 100% 100% url(${mobileFrame})`,
              padding: "12px 12px",
              "& > div": {
                borderRadius: 12,
                width: "100%",
                height: "100%",
              },
            },
            "& > div#home": {
              "& > div": {
                background: `no-repeat center / 100% 100% url(${mobileHome})`,
              },
            },
            "& > div#notice": {
              "& > div": {
                background: `no-repeat center / 100% 100% url(${mobileNotice})`,
              },
            },
            "& > div#fee": {
              "& > div": {
                background: `no-repeat center / 100% 100% url(${mobileFee})`,
              },
            },
            "& > div#vehicle": {
              "& > div": {
                background: `no-repeat center / 100% 100% url(${mobileVehicle})`,
              },
            },
          },
          "& > .fabs": {
            display: "flex",
            justifyContent: "space-between",
            position: "absolute",
            marginTop: -20,
            width: 320,
            "& > button": {
              color: "white",
              background: theme.palette.blue,
              width: 36,
              height: 36,
            },
          },
        },
      },
      // NOTE section4 style
      "&:nth-of-type(4)": {
        background: "#F6FEFF",
        "& > .buttonGroup": {
          width: 320,
          marginTop: 28,
          marginBottom: 20,
          "& > button": {
            height: 28,
            borderRadius: 16,
            transition: "none",
            margin: "4px 0px",
            "& .MuiButton-label": {
              color: theme.palette.text.secondary,
              fontSize: 12,
            },
          },
          "& > .active": {
            background: "#00BCD4",
            "& .MuiButton-label": {
              color: "white",
              fontWeight: 500,
            },
          },
        },
        "& > .tabPanelWrapper": {
          display: "flex",
          position: "relative",
          justifyContent: "center",
          alignItems: "center",
          width: 300,
          height: 220,
          background: `no-repeat center / 100% 100% url(${monitorFrame})`,
          padding: 3,
          paddingBottom: 31,
          ...[
            { className: "monitorDashboard", img: monitorDashboard },
            { className: "monitorParkingSpace", img: monitorParkingSpace },
            { className: "monitorRealtimeVideo", img: monitorRealtimeVideo },
            { className: "monitorVideoRecord", img: monitorVideoRecord },
            { className: "monitorRealtimeInOut", img: monitorRealtimeInOut },
            { className: "monitorInOutRecord", img: monitorInOutRecord },
            { className: "monitorVehicle", img: monitorVehicle },
            { className: "monitorParkingFee", img: monitorParkingFee },
          ].reduce(
            (prev, tab) => ({
              ...prev,
              [`& > .${tab.className}`]: {
                height: "100%",
                width: "100%",
                transition: "background 0.5s ease-in-out",
                background: `no-repeat center / 100% 100% url(${tab.img})`,
              },
            }),
            {}
          ),
          "& > .fabs": {
            display: "flex",
            justifyContent: "space-between",
            position: "absolute",
            width: "min(100vw, 370px)",
            "& > button": {
              color: "white",
              background: theme.palette.blue,
              width: 30,
              height: 30,
              minHeight: 30,
            },
          },
        },
        "& > .text": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "#E1F5FE",
          height: 72,
          marginTop: 32,
          width: "100%",
          fontSize: 16,
        },
      },
      // NOTE section5 style
      "&:nth-of-type(5)": {
        "& > .title": {
          fontFamily: "Montserrat",
          fontSize: 30,
          fontWeight: 900,
          lineHeight: "34px",
          letterSpacing: 0,
        },
        "& > .contactInfo": {
          boxShadow: `0px 3px 6px #00000030`,
          margin: "16px 20px 16px",
          padding: "16px 24px",
          width: "calc(100% - 40px)",
          textAlign: "left",
          "& > .subtitle": {
            fontFamily: "Montserrat",
            fontWeight: 700,
            fontSize: 16,
          },
          "& > .text": {
            color: theme.palette.text.secondary,
            fontSize: 12,
          },
          "& > hr": {
            border: "none",
            margin: 6,
          },
        },
        "& > .map": {
          margin: "0px 20px 0px",
          width: "calc(100% - 40px)",
          height: 400,
        },
      },
    },
  },
  fab: {
    zIndex: 4,
    position: "absolute",
    bottom: 8,
    right: 16,
    width: 36,
    height: 36,
    background: "white",
    border: `2px solid ${theme.palette.blue}`,
    "& svg": { width: "60%", height: "60%" },
  },
}));

const floatingCirclePositions = [
  [
    { top: "8vh", left: "4vw" },
    { top: "13vh", left: "15vw" },
    { top: "15vh", left: "8vw" },
    { top: "20vh", left: "2vw" },
    { top: "40vh", left: "2vw" },
    { top: "30vh", left: "15vw" },
  ],
  [
    { top: "48vh", left: "80vw" },
    { top: "55vh", left: "75vw" },
    { top: "65vh", left: "80vw" },
    { top: "75vh", left: "70vw" },
    { top: "80vh", left: "80vw" },
    { top: "87vh", left: "80vw" },
  ],
  [
    { top: "80vh", left: "90vw" },
    { top: "82vh", left: "70vw" },
    { top: "85vh", left: "80vw" },
    { top: "87vh", left: "60vw" },
    { top: "90vh", left: "88vw" },
    { top: "95vh", left: "70vw" },
  ],
  [
    { top: "55vh", left: "5vw" },
    { top: "60vh", left: "10vw" },
    { top: "65vh", left: "8vw" },
    { top: "70vh", left: "15vw" },
    { top: "75vh", left: "4vw" },
    { top: "8svh", left: "5vw" },
  ],
];

export const LandingMobile = () => {
  const classes = useStyles();
  const mainRef = useRef(null);
  const [sectionIdx, setSectionIdx] = useState(0);

  useEffect(() => {
    // to fix scroll-snap bug in Chrome

    // modern Chrome requires { passive: false } when adding event
    let supportsPassive = false;
    try {
      window.addEventListener(
        "test",
        null,
        Object.defineProperty({}, "passive", {
          get: () => {
            supportsPassive = true;
            return true;
          },
        })
      );
    } catch (error) {}
    const wheelOpt = supportsPassive ? { passive: false } : false;
    const wheelEvent =
      "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";

    const handleWheel = (e) => {
      if (e.path.some((el) => el.className === "map")) return;

      e.preventDefault();
      if (e.deltaY < 0 && sectionIdx > 0) {
        mainRef.current.scrollTo({
          top: mainRef.current.children[sectionIdx - 1].offsetTop,
          behavior: "smooth",
        });
      } else if (
        e.deltaY > 0 &&
        sectionIdx < mainRef.current.children.length - 1
      ) {
        mainRef.current.scrollTo({
          top: mainRef.current.children[sectionIdx + 1].offsetTop,
          behavior: "smooth",
        });
      }
    };
    window.addEventListener(wheelEvent, handleWheel, wheelOpt);
    return () => {
      window.removeEventListener(wheelEvent, handleWheel, wheelOpt);
    };
  }, [sectionIdx]);

  const handleNavClick = (newSectionIdx) => {
    mainRef.current.scrollTo({
      top: mainRef.current.children[newSectionIdx].offsetTop,
      behavior: "smooth",
    });
  };

  const handleMainScroll = (e) => {
    let newSectionIdx;
    newSectionIdx = Array.from(e.target.children).findIndex(
      (c) => e.target.scrollTop <= c.offsetTop
    );
    if (newSectionIdx > -1) setSectionIdx(newSectionIdx);
  };

  // NOTE section1
  const [contactDialogOpen, setContactDialogOpen] = useState(false);
  const handleContactDialogClick = () => {
    setContactDialogOpen(true);
  };
  const handleContactDialogClose = () => {
    setContactDialogOpen(false);
  };

  // NOTE section3
  const [order, setOrder] = useState([
    "fee",
    "vehicle",
    "home",
    "notice",
    "fee",
  ]);
  const [isMoving, setIsMoving] = useState(false);
  const [touchStart, setTouchStart] = useState(null);
  const handleUserFabClick = (value) => () => {
    setIsMoving(value);
  };
  const handleTouchStart = (e) => {
    setTouchStart(e.changedTouches[0].clientX);
  };
  const handleTouchEnd = (e) => {
    if (touchStart) {
      const touchEnd = e.changedTouches[0].clientX;
      if (touchStart - touchEnd > 0) {
        setIsMoving(1);
        setTouchStart(null);
      } else if (touchStart - touchEnd < 0) {
        setIsMoving(-1);
        setTouchStart(null);
      }
    }
  };
  const handelTransitionEnd = () => {
    let newOrder = order;
    if (isMoving > 0) {
      newOrder.shift();
      newOrder.push(newOrder[0]);
    } else if (isMoving < 0) {
      newOrder.pop();
      newOrder.unshift(newOrder[newOrder.length - 1]);
    }
    setOrder(newOrder);
    setIsMoving(false);
  };
  let styles;
  if (isMoving > 0) {
    styles = [
      {
        transition: ".5s",
        transform: "scale(0.6) translate(-420%)",
        opacity: 0.7,
      },
      {
        transition: ".5s",
        transform: "scale(0.6) translate(-320%)",
        opacity: 0.7,
      },
      {
        transition: ".5s",
        transform: "scale(0.6) translate(-150%)",
        opacity: 0.7,
      },
      { transition: ".5s" },
      {
        transition: ".5s",
        transform: "scale(0.6) translate(150%)",
        opacity: 0.7,
      },
    ];
  } else if (isMoving < 0) {
    styles = [
      {
        transition: ".5s",
        transform: "scale(0.6) translate(-150%)",
        opacity: 0.7,
      },
      { transition: ".5s" },
      {
        transition: ".5s",
        transform: "scale(0.6) translate(150%)",
        opacity: 0.7,
      },
      {
        transition: ".5s",
        transform: "scale(0.6) translate(320%)",
        opacity: 0.7,
      },
      {
        transition: ".5s",
        transform: "scale(0.6) translate(420%)",
        opacity: 0.7,
      },
    ];
  } else {
    styles = [
      { transform: "scale(0.6) translate(-320%)", opacity: 0.7 },
      { transform: "scale(0.6) translate(-150%)", opacity: 0.7 },
      {},
      { transform: "scale(0.6) translate(150%)", opacity: 0.7 },
      { transform: "scale(0.6) translate(320%)", opacity: 0.7 },
    ];
  }
  let titleAndContent = { title: "", content: "" };
  switch (order[2]) {
    case "home":
      titleAndContent.title = "Home";
      titleAndContent.content = (
        <>
          입출차 알림, 주차요금 조회, 등록 차량 관리
          <br />
          쉽고 편리한 내 손 안의 우리집 스마트 주차장
        </>
      );
      break;
    case "notice":
      titleAndContent.title = "입출차 알림";
      titleAndContent.content = (
        <>
          차량 입출차 시 내 휴대폰으로 알림을!
          <br />
          입출차 알림 내역 검색으로 더욱 편리하게
        </>
      );
      break;
    case "fee":
      titleAndContent.title = "주차요금 조회";
      titleAndContent.content = (
        <>
          실시간으로 확인 가능한 우리집 주차 요금
          <br />
          월별 요금 명세서 확인도 간편하게
        </>
      );
      break;
    case "vehicle":
      titleAndContent.title = "등록차량 관리";
      titleAndContent.content = (
        <>
          우리집 차량과 방문 차량을 미리 등록하면
          <br />
          입출차 시 별도의 절차 없이 빠르고 편리하게
        </>
      );
      break;
    default:
      break;
  }

  // NOTE section4
  const [tabIdx, setTabIdx] = useState(0);
  const tabs = [
    {
      label: "대시보드",
      className: "monitorDashboard",
      img: monitorDashboard,
      text: (
        <>
          주차장 운영 상황과 통계를 전반적으로 보여주며,
          <br /> 미등록 차량의 사진 및 정보를 확인할 수 있습니다.
        </>
      ),
    },
    {
      label: "주차 공간",
      className: "monitorParkingSpace",
      img: monitorParkingSpace,
      text: (
        <>
          지도를 통해 각 층의 주차 공간을 보여주며,
          <br />
          혼잡도 및 주차된 차량의 정보를 확인할 수 있습니다.
        </>
      ),
    },
    {
      label: "실시간 영상",
      className: "monitorRealtimeVideo",
      img: monitorRealtimeVideo,
      text: (
        <>
          각 카메라로부터 전송된 실시간 영상을 보여주며,
          <br /> 영상을 클릭하면 전체 화면으로 볼 수 있습니다.
        </>
      ),
    },
    {
      label: "영상 기록",
      className: "monitorVideoRecord",
      img: monitorVideoRecord,
      text: (
        <>
          녹화된 영상을 보여주며, 차종, 차량 번호, 사람 유무,
          <br />
          날짜/시간 등을 이용해 영상을 검색할 수 있습니다.
        </>
      ),
    },
    {
      label: "실시간 입출차",
      className: "monitorRealtimeInOut",
      img: monitorRealtimeInOut,
      text: (
        <>
          각 출입구의 실시간 영상을 확인할 수 있습니다.
          <br />
          영상에 차량이 인식되면 차량 정보를 보여줍니다.
        </>
      ),
    },
    {
      label: "입출차 기록",
      className: "monitorInOutRecord",
      img: monitorInOutRecord,
      text: (
        <>
          현재까지의 차량 입출차 기록을 보여줍니다.
          <br />
          검색창을 이용해 입출차 기록을 검색할 수 있습니다.
        </>
      ),
    },
    {
      label: "차량 등록 및 관리",
      className: "monitorVehicle",
      img: monitorVehicle,
      text: (
        <>
          새로운 차량을 등록하거나,
          <br /> 등록 차량의 상세 정보를 열람할 수 있습니다.
        </>
      ),
    },
    {
      label: "주차 요금",
      className: "monitorParkingFee",
      img: monitorParkingFee,
      text: (
        <>
          주차 요금을 설정 및 변경하거나,
          <br />각 세대별 요금 명세서를 확인할 수 있습니다.
        </>
      ),
    },
  ];
  const handleTabClick = (newTabIdx) => () => {
    setTabIdx(newTabIdx);
  };
  const handleManagerFabClick = (value) => () => {
    const newTabIdx = tabIdx + value;
    if (newTabIdx > -1 && newTabIdx < tabs.length) setTabIdx(newTabIdx);
    else if (newTabIdx === -1) setTabIdx(tabs.length - 1);
    else if (newTabIdx === tabs.length) setTabIdx(0);
  };
  useEffect(() => {
    let timer;
    clearInterval(timer);
    timer = setInterval(() => {
      let newTabIdx = tabIdx + 1;
      if (newTabIdx > tabs.length - 1) newTabIdx = 0;

      setTabIdx(newTabIdx);
    }, 3000);
    return () => {
      clearInterval(timer);
    };
  }, [tabIdx, tabs.length]);

  // NOTE fab
  const handleGoToTop = () => {
    mainRef.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {floatingCirclePositions.map((position, idx) => (
        <div
          key={`circle${idx}`}
          className={classes.floatingCircle}
          id={`circle${idx}`}
          style={position[sectionIdx]}
        />
      ))}
      <Header handleClick={handleNavClick} sectionIdx={sectionIdx} />
      <main className={classes.main} ref={mainRef} onScroll={handleMainScroll}>
        <section>
          <Typography className="title">
            <span>똑똑</span>하고 <span>편리</span>한,
            <br />내 손 안의
            <br />
            우리집 스마트 주차장
          </Typography>
          <Typography className="subTitle">
            파이파킹 스마트 주차관리 시스템
          </Typography>
          <Button
            className="contactButton"
            endIcon={<ChevronRightIcon />}
            onClick={handleContactDialogClick}
          >
            서비스 문의
          </Button>
          <div className="demo">
            <div className="mobile">
              <div />
            </div>
            <div className="monitor">
              <div />
            </div>
          </div>
        </section>
        <section>
          <Typography className="title">
            파이파킹이 만드는
            <br />
            새로운 주차 공간
          </Typography>
          <Typography className="paragraph">
            파이파킹은 <span>딥러닝 기술을 활용한</span>
            <br />
            <span>스마트 주차관리 시스템</span> 개발 업체로,
            <br />
            그동안 공동주택에 적용되지 못했던
            <br />
            새로운 주차관리 시스템을 만듭니다.
            <br />
            <br />
            파이파킹의 기술을 통해
            <br />
            우리의 주차 공간은 더 똑똑하고,
            <br />
            편리해질 수 있습니다.
            <br />
            <br />
            <span>우리 아파트에 꼭 필요한 주차장,</span>
            <br />
            파이파킹이 만들어 갑니다.
          </Typography>
          <img src={corpIntroIllust} alt="corpIntroIllust" />
        </section>
        <section>
          <Typography className="title">
            쉽고 편리한
            <br />
            사용자 어플리케이션
          </Typography>
          <Typography
            className="subTitle"
            style={isMoving ? { opacity: 0 } : { opacity: 1 }}
          >
            {titleAndContent.title}
          </Typography>
          <Typography
            className="text"
            style={isMoving ? { opacity: 0 } : { opacity: 1 }}
          >
            {titleAndContent.content}
          </Typography>
          <div
            className="demo"
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
          >
            <div className="circles">
              <div />
              <div />
              <div />
              <div />
            </div>
            <div className="mobiles">
              <div
                id={order[0]}
                style={styles[0]}
                onTransitionEnd={handelTransitionEnd}
              >
                <div />
              </div>
              <div id={order[1]} style={styles[1]}>
                <div />
              </div>
              <div id={order[2]} style={styles[2]}>
                <div />
              </div>
              <div id={order[3]} style={styles[3]}>
                <div />
              </div>
              <div id={order[4]} style={styles[4]}>
                <div />
              </div>
            </div>
            <div className="fabs">
              <Fab>
                <ChevronLeftIcon onClick={handleUserFabClick(-1)} />
              </Fab>
              <Fab>
                <ChevronRightIcon onClick={handleUserFabClick(1)} />
              </Fab>
            </div>
          </div>
        </section>
        <section>
          <Typography className="title">
            체계적이고 효율적인
            <br />웹 기반 주차관제 시스템
          </Typography>
          <div className="buttonGroup">
            {tabs.map((tab, idx) => (
              <Button
                key={tab.className}
                onClick={handleTabClick(idx)}
                className={tabIdx === idx ? "active" : ""}
              >
                {tab.label}
              </Button>
            ))}
          </div>
          <div className="tabPanelWrapper">
            <div className={tabs[tabIdx].className} />
            <div className="fabs">
              <Fab>
                <ChevronLeftIcon onClick={handleManagerFabClick(-1)} />
              </Fab>
              <Fab>
                <ChevronRightIcon onClick={handleManagerFabClick(1)} />
              </Fab>
            </div>
          </div>
          <Typography className="text">{tabs[tabIdx].text}</Typography>
        </section>
        <section>
          <Typography className="title">CONTACT</Typography>
          <div className="contactInfo">
            <Typography className="subtitle">ADDRESS</Typography>
            <Typography className="text">
              서울시 성동구 왕십리로 222, 한양대학교 IT/BT관 409호
            </Typography>
            <hr />
            <Typography className="subtitle">TEL</Typography>
            <Typography className="text">02-2220-0392</Typography>
            <hr />
            <Typography className="subtitle">EMAIL</Typography>
            <Typography className="text">contact@paiparking.com</Typography>
          </div>
          <Map className="map" />
        </section>
        <Footer onLogoClick={handleNavClick} />
      </main>
      <ContactDialog
        open={contactDialogOpen}
        onClose={handleContactDialogClose}
      />
      <Zoom in={sectionIdx > 0} unmountOnExit>
        <Fab className={classes.fab} onClick={handleGoToTop}>
          <ChevronUpIcon />
        </Fab>
      </Zoom>
    </>
  );
};
