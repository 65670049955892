import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

import { Map } from "./Map";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    zIndex: 1,
    "& > h1": {
      margin: theme.spacing(15, "auto", 12.5),
      fontFamily: "Montserrat",
      fontSize: theme.spacing(13),
      fontWeight: 700,
    },
    "& > #map": {
      marginBottom: theme.spacing(23.5),
      width: theme.spacing(360),
      height: theme.spacing(125),
    },
    "& > #contactInfo": {
      position: "absolute",
      background: "white",
      boxShadow: "0px 3px 6px #00000030",
      top: theme.spacing(60),
      left: theme.spacing(10),
      padding: theme.spacing(10),
      "& > h2": {
        marginBottom: theme.spacing(2),
        fontFamily: "Montserrat",
        fontSize: theme.spacing(6),
        fontWeight: 700,
      },
      "& > p": {
        marginBottom: theme.spacing(9),
        color: theme.palette.text.secondary,
        fontSize: theme.spacing(5),
        "&:last-child": {
          marginBottom: 0,
        },
      },
    },
  },
}));

export const LandingContact = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h1">CONTACT</Typography>
      <Map id="map" />
      <div id="contactInfo">
        <Typography variant="h2">ADDRESS</Typography>
        <Typography>
          서울시 성동구 왕십리로 222, 한양대학교 IT/BT관 409호
        </Typography>
        <Typography variant="h2">TEL</Typography>
        <Typography>02-2220-0392</Typography>
        <Typography variant="h2">EMAIL</Typography>
        <Typography>contact@paiparking.com</Typography>
      </div>
    </div>
  );
};
