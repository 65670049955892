import { createMuiTheme } from "@material-ui/core";

export const theme = createMuiTheme({
  spacing: (factor) => `${0.4 * factor}rem`, // spacing: 4,
  palette: {
    text: { blue: "#2945FF" },
    blue: "#2945FF",
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 609, //mobile version
      md: 1044, //under870=tablet => header hamburger
      lg: 1218,
      xl: 1740,
    },
  },
  typography: {
    // Thin 100
    // Light 300
    // Regular 400
    // Medium 500
    // Bold 700
    // Black 900
    fontFamily: ["Noto Sans KR"].join(","),
  },
  sizes: {
    Header: {
      mdHeight: 64,
      lgHeight: 80,
      mdLogoHeight: 32,
      lgLogoHeight: 36,
      mdButtonFontSize: 16,
      lgButtonFontSize: 18,
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          height: "100%",
          letterSpacing: -0.8,
          // xs: 0,
          // sm: 609,
          // md: 870,
          // lg: 1218,
          // xl: 1740,
          ...[...new Array(14)]
            .map((_, idx) => (idx + 7) * 0.5)
            .reduce(
              (acc, fontSize) => ({
                ...acc,
                ["@media (min-width: " + (1740 / 10) * fontSize + "px)"]: {
                  fontSize,
                },
              }),
              {}
            ),
        },
        body: { height: "100%" },
        "#root": { height: "100%" },
      },
    },
    MuiButton: {
      label: { letterSpacing: -0.8 },
    },
  },
});
