import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { corpIntroIllust } from "../assets";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    zIndex: 1,
    "& > h1": {
      fontSize: theme.spacing(15),
      fontWeight: 700,
      marginBottom: theme.spacing(16),
    },
    "& > div": {
      zIndex: 2,
      "& > p": {
        fontSize: theme.spacing(7.5),
        textAlign: "center",
        lineHeight: 1.45,
      },
      "& > p > span": {
        display: "inline-block",
        lineHeight: theme.spacing(8),
        fontWeight: 700,
        background: "#FFF176",
        borderRadius: theme.spacing(1),
        padding: theme.spacing(0.5, 1),
      },
    },
    "& > img": {
      width: theme.spacing(360),
      marginTop: theme.spacing(10),
      zIndex: 1,
    },
  },
}));

export const LandingCorpIntro = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h1">파이파킹이 만드는 새로운 주차 공간</Typography>
      <div>
        <Typography>
          파이파킹은&nbsp;
          <span>딥 러닝 기술을 활용한 스마트 주차관리 시스템</span>
          &nbsp;개발 업체로서,
          <br />
          그동안 공동주택에 적용되지 못했던 새로운 주차관리 시스템을 만듭니다.
          <br />
          <br />
          파이파킹의 기술을 통해 우리의 주차 공간은 더 똑똑하고, 편리해질 수
          있습니다.
          <br />
          <span>우리 아파트에 꼭 필요한 주차장,</span>
          &nbsp;파이파킹이 만들어 갑니다.
        </Typography>
      </div>
      <img src={corpIntroIllust} alt="corpIntroIllust" />
    </div>
  );
};
