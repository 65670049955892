import React from "react";
import {
  makeStyles,
  Typography,
  Divider,
  Hidden,
  useMediaQuery,
} from "@material-ui/core";

import { logo } from "../assets";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    background: "#F6FEFF",
    height: theme.spacing(54),
    padding: theme.spacing(
      11,
      `calc(100vw / 2 - ${theme.spacing(360)} / 2)`,
      11
    ),
    gap: theme.spacing(15),
    "& > img": {
      height: theme.spacing(9),
      padding: theme.spacing(1.5, 2),
    },
    "& > div": {
      "& > .termsOfUse": {
        display: "flex",
        gap: theme.spacing(10),
        marginBottom: theme.spacing(5),
        "& > p": { fontSize: theme.spacing(3.5), fontWeight: 500 },
        "& > p.copyRight": { marginLeft: theme.spacing(30) },
      },
      "& > .corpInfo": {
        "& p": {
          fontSize: theme.spacing(3.5),
          color: theme.palette.text.secondary,
        },
        "& hr": { margin: theme.spacing(0.5, 2) },
        "& > div": {
          display: "flex",
          marginBottom: theme.spacing(2),
        },
      },
      "& > .copyRight": {
        marginTop: theme.spacing(4),
        fontSize: theme.spacing(3.5),
        color: theme.palette.text.secondary,
      },
    },
  },
  columnFooter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "#F6FEFF",
    padding: "40px 0px",
    gap: 4,
    color: theme.palette.text.secondary,
    "& p": { fontSize: 12, whiteSpace: "nowrap" },
    "& > img": { height: 24, marginBottom: 12 },
    "& > .termsOfUse": {
      display: "flex",
      gap: 24,
      margin: "8px 0px",
      "& > p": {
        color: theme.palette.text.primary,
        fontWeight: 500,
      },
    },
  },
}));

export const Footer = ({ onLogoClick }) => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:696px)");

  return matches ? (
    <footer className={classes.root}>
      <img src={logo} alt="PAI Parking" onClick={() => onLogoClick(0)} />
      <div>
        <div className="termsOfUse">
          <Typography>이용약관</Typography>
          <Typography>개인정보처리방침</Typography>
          <Hidden mdUp>
            <Typography className="copyRight">ⓒ PAI Parking, Inc.</Typography>
          </Hidden>
        </div>
        <div className="corpInfo">
          <div>
            <Typography>파이파킹 주식회사</Typography>
            <Divider orientation="vertical" flexItem />
            <Typography>대표이사: 김민구</Typography>
            <Divider orientation="vertical" flexItem />
            <Typography>사업자등록번호: 738-81-02233</Typography>
          </div>
          <div>
            <Typography>
              (04763) 서울시 성동구 왕십리로 222, 한양대학교 IT/BT관 409호
            </Typography>
            <Divider orientation="vertical" flexItem />
            <Typography>대표번호: 02-2220-0392</Typography>
            <Divider orientation="vertical" flexItem />
            <Typography>이메일: contact@paiparking.com</Typography>
          </div>
        </div>
        <Hidden smDown>
          <Typography className="copyRight">ⓒ PAI Parking, Inc.</Typography>
        </Hidden>
      </div>
    </footer>
  ) : (
    <footer className={classes.columnFooter}>
      <img src={logo} alt="PAI Parking" onClick={() => onLogoClick(0)} />
      <Typography>
        파이파킹 주식회사&nbsp;&nbsp;|&nbsp;&nbsp;대표이사: 김민구
      </Typography>
      <Typography>사업자등록번호: 738-81-02233</Typography>
      <Typography>대표번호: 02-2220-0392</Typography>
      <Typography>이메일: contact@paiparking.com</Typography>
      <Typography>
        (04763) 서울시 성동구 왕십리로 222, 한양대학교 IT/BT관 409호
      </Typography>
      <div className="termsOfUse">
        <Typography>이용약관</Typography>
        <Typography>개인정보처리방침</Typography>
      </div>
      <Typography className="copyRight">ⓒ PAI Parking, Inc.</Typography>
    </footer>
  );
};
