import React from "react";
import { SvgIcon } from "@material-ui/core";

export const ChevronLeftIcon = (props) => (
  <SvgIcon {...props}>
    <path fill="currentColor" d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
  </SvgIcon>
);
export const ChevronRightIcon = (props) => (
  <SvgIcon {...props}>
    <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
  </SvgIcon>
);

export const CloseIcon = (props) => (
  <SvgIcon {...props}>
    <path
      fill="currentColor"
      d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
    />
  </SvgIcon>
);

export const ChevronUpIcon = (props) => (
  <SvgIcon {...props}>
    <path fill="currentColor" d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z" />
  </SvgIcon>
);
