import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    overflow: "hidden",
    width: 2000,
    height: 2000,
  },
  map: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
}));

export const Map = ({ className, ...rest }) => {
  const classes = useStyles();

  useEffect(() => {
    const installKakaoMapScript = () => {
      if (window.daum && window.daum.roughmap && window.daum.roughmap.cdn) {
        return;
      }
      const c = window.location.protocol === "https:" ? "https:" : "http:";
      const a = "16137cec";
      window.daum = window.daum || {};
      window.daum.roughmap = {
        cdn: a,
        URL_KEY_DATA_LOAD_PRE: c + "//t1.daumcdn.net/roughmap/",
        url_protocal: c,
      };
      const b = c + "//t1.daumcdn.net/kakaomapweb/place/jscss/roughmap/" + a + "/roughmapLander.js";

      const scriptTag = document.createElement("script");
      scriptTag.src = b;
      scriptTag.async = true;
      scriptTag.onload = () => {
        new window.daum.roughmap.Lander({
          timestamp: "1624297510047",
          key: "26bjs",
          mapWidth: 2000,
          mapHeight: 2000,
        }).render();
      };

      document.head.append(scriptTag);
    };

    installKakaoMapScript();
  }, []);

  return (
    <div className={`${classes.root} ${className}`} {...rest}>
      <div
        id="daumRoughmapContainer1624297510047"
        className={`${classes.map} root_daum_roughmap root_daum_roughmap_landing`}
      />
    </div>
  );
};
