import React, { useEffect, useRef, useState } from "react";
import {
  makeStyles,
  Button,
  Hidden,
  Drawer,
  List,
  ListItem,
  Divider,
  Typography,
  Popper,
  ClickAwayListener,
} from "@material-ui/core";

import { logo } from "../assets";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    height: theme.sizes.Header.mdHeight,
    [theme.breakpoints.up("lg")]: { height: theme.sizes.Header.lgHeight },
    zIndex: 1000,
    background: theme.palette.common.white,
    boxShadow: `0px 3px 6px #00000030`,
    [theme.breakpoints.down("sm")]: { boxShadow: `0px 2px 4px #00000030` },
    transition: "0.2s box-shadow ease-in-out",
    "&.top": { boxShadow: `none` },
    "& > div": {
      display: "flex",
      justifyContent: "center",
      height: "100%",
      "& > div": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "95%",
        [theme.breakpoints.up("md")]: { width: theme.spacing(360) },
        transition: "0.5s width ease-in-out",
        "&.top": {
          width: "95%",
          [theme.breakpoints.up("md")]: { width: theme.spacing(420) },
        },
        "& > img": {
          height: theme.sizes.Header.mdLogoHeight,
          [theme.breakpoints.up("lg")]: {
            height: theme.sizes.Header.lgLogoHeight,
          },
          padding: "6px 8px",
        },
        "& > nav": {
          display: "flex",
          gap: theme.spacing(25),
          "& > button": {
            fontSize: theme.sizes.Header.mdButtonFontSize,
            [theme.breakpoints.up("lg")]: {
              fontSize: theme.sizes.Header.lgButtonFontSize,
            },
            padding: "6px 8px",
            fontWeight: 400,
            "&.active": { fontWeight: 700 },
          },
        },
      },
    },
  },
  menuList: {
    display: "flex",
    flexDirection: "column",
    background: "white",
    boxShadow: "0px 3px 6px #00000030",
    borderRadius: "0px 0px 4px 4px",
    borderTop: "2px solid black",
    padding: "12px 0px",
    [theme.breakpoints.up("lg")]: { marginTop: 4 },
    "& > button": {
      fontSize: 14,
      [theme.breakpoints.up("lg")]: { fontSize: 16 },
      padding: theme.spacing(2, 5),
      fontWeight: 400,
      "&.active": { fontWeight: 700 },
    },
  },
  drawerButton: {
    position: "fixed",
    zIndex: 3000,
    right: 0,
    height: theme.sizes.Header.mdHeight,
    borderRadius: theme.sizes.Header.mdHeight,
    "& #nav-icon": {
      width: 24,
      height: 17,
      transform: "rotate(0deg)",
      transition: ".5s ease-in-out",
      cursor: "pointer",
      "& > span": {
        display: "block",
        position: "absolute",
        height: 3,
        width: "100%",
        background: "black",
        borderRadius: 3,
        opacity: 1,
        left: 0,
        transform: "rotate(0deg)",
        transition: ".25s ease-in-out",
      },
      "& > span:nth-child(1)": { top: 0 },
      "& > span:nth-child(2)": { top: 7 },
      "& > span:nth-child(3)": { top: 7 },
      "& > span:nth-child(4)": { top: 14 },

      "&.open > span:nth-child(1)": { top: 7, width: 0, left: "50%" },
      "&.open > span:nth-child(2)": { transform: "rotate(45deg)" },
      "&.open > span:nth-child(3)": { transform: "rotate(-45deg)" },
      "&.open > span:nth-child(4)": { top: 7, width: 0, left: "50%" },
    },
  },
  drawer: {
    "& nav": {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      width: 280,
      marginTop: 64,
      padding: 0,
      "& > div": { fontSize: 22, fontWeight: 700, padding: "12px 32px" },
      "& > .nestedList": {
        marginTop: -6,
        marginBottom: 6,
        padding: 0,
        "& > .nestedItem": {
          padding: "6px 48px",
          fontSize: 18,
          fontWeight: 400,
        },
      },
    },
    "& hr": { margin: "0px 20px" },
    "& .footer": {
      padding: "28px 32px 32px",
      "& > img": { height: 24, marginBottom: 12 },
      "& > p": { fontSize: 12, color: theme.palette.text.secondary },
      "& > p:nth-of-type(1)": { marginBottom: 4 },
    },
  },
}));

export const Header = ({ sectionIdx, handleClick }) => {
  const classes = useStyles();
  const [isTop, setIsTop] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuAnchorRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) setIsTop(true);
      else setIsTop(false);
    };
    handleScroll();

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    setIsMenuOpen(false);
    window.addEventListener("resize", () => setIsMenuOpen(false));
    return () => {
      window.removeEventListener("resize", () => setIsMenuOpen(false));
    };
  }, [isTop]);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleNavClick = (idx) => {
    handleClick(idx);
    setIsDrawerOpen(false);
    setIsMenuOpen(false);
  };

  return (
    <>
      <header
        className={`${classes.root}${isTop && sectionIdx === 0 ? " top" : ""}`}
      >
        <div>
          <div className={isTop ? "top" : ""}>
            <img
              src={logo}
              alt="PAI Parking"
              onClick={() => handleNavClick(0)}
            />
            <Hidden smDown>
              <nav>
                <Button
                  className={sectionIdx === 0 ? "active" : ""}
                  onClick={() => handleNavClick(0)}
                >
                  HOME
                </Button>
                <Button
                  className={sectionIdx === 1 ? "active" : ""}
                  onClick={() => handleNavClick(1)}
                >
                  회사 소개
                </Button>
                <Button
                  className={
                    sectionIdx === 2 || sectionIdx === 3 ? "active" : ""
                  }
                  onClick={toggleMenu}
                  ref={menuAnchorRef}
                >
                  서비스 소개
                </Button>
                <Popper
                  open={isMenuOpen}
                  anchorEl={menuAnchorRef.current}
                  transition
                  disablePortal
                >
                  <ClickAwayListener onClickAway={toggleMenu}>
                    <div className={classes.menuList}>
                      <Button
                        className={sectionIdx === 2 ? "active" : ""}
                        onClick={() => handleNavClick(2)}
                      >
                        사용자 어플리케이션
                      </Button>
                      <Button
                        className={sectionIdx === 3 ? "active" : ""}
                        onClick={() => handleNavClick(3)}
                      >
                        주차관제 시스템
                      </Button>
                    </div>
                  </ClickAwayListener>
                </Popper>
                <Button
                  className={sectionIdx === 4 ? "active" : ""}
                  onClick={() => handleNavClick(4)}
                >
                  CONTACT
                </Button>
              </nav>
            </Hidden>
          </div>
        </div>
      </header>

      <Hidden mdUp>
        <Button
          onClick={toggleDrawer}
          className={classes.drawerButton}
          disableRipple
        >
          <div id="nav-icon" className={isDrawerOpen ? " open" : ""}>
            <span />
            <span />
            <span />
            <span />
          </div>
        </Button>
        <Drawer
          className={classes.drawer}
          anchor="right"
          open={isDrawerOpen}
          onClose={toggleDrawer}
        >
          <List component="nav">
            <ListItem button onClick={() => handleNavClick(0)}>
              HOME
            </ListItem>
            <ListItem button onClick={() => handleNavClick(1)}>
              회사 소개
            </ListItem>
            <ListItem
              button
              className="nestedTitle"
              onClick={() => handleNavClick(2)}
            >
              서비스 소개
            </ListItem>
            <List component="div" className="nestedList" disablePadding>
              <ListItem
                button
                className="nestedItem"
                onClick={() => handleNavClick(2)}
              >
                사용자 어플리케이션
              </ListItem>
              <ListItem
                button
                className="nestedItem"
                onClick={() => handleNavClick(3)}
              >
                주차관제 시스템
              </ListItem>
            </List>
            <ListItem button onClick={() => handleNavClick(4)}>
              CONTACT
            </ListItem>
          </List>
          <Divider />
          <div className="footer">
            <img
              src={logo}
              alt="PAI Parking"
              onClick={() => handleNavClick(0)}
            />
            <Typography>파이파킹 주식회사</Typography>
            <Typography>ⓒ PAI Parking, Inc.</Typography>
          </div>
        </Drawer>
      </Hidden>
    </>
  );
};
