import React from "react";
import { ThemeProvider, CssBaseline, useMediaQuery } from "@material-ui/core";

import { theme } from "./constants/theme";
import { LandingWeb, LandingMobile } from "./pages";

export const App = () => {
  const matches = useMediaQuery("(min-width:609px)");

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {matches ? <LandingWeb /> : <LandingMobile />}
    </ThemeProvider>
  );
};
