import React, { useEffect, useRef, useState } from "react";
import {
  makeStyles,
  Fab,
  useMediaQuery,
  useTheme,
  Zoom,
} from "@material-ui/core";

import {
  Header,
  LandingHome,
  LandingCorpIntro,
  LandingUser,
  LandingManager,
  LandingContact,
  Footer,
} from "../components";
import { ChevronUpIcon } from "../components/icons";

const useStyles = makeStyles((theme) => ({
  main: {
    position: "relative",
    "& > section": {
      position: "relative",
      display: "flex",
      overflow: "hidden",
      alignItems: "center",
      width: "100%",
      minHeight: theme.spacing(270),
      background: "white",
      padding: theme.spacing(
        0,
        `calc(100vw / 2 - ${theme.spacing(360)} / 2)`,
        0
      ),
      [theme.breakpoints.up("md")]: {
        height: "100vh",
        padding: theme.spacing(
          `${theme.sizes.Header.mdHeight}px`,
          `calc(100vw / 2 - ${theme.spacing(360)} / 2)`,
          0
        ),
      },
      [theme.breakpoints.up("lg")]: { paddingTop: theme.sizes.Header.lgHeight },
    },
    "& > section:nth-of-type(1)": {
      padding: theme.spacing(
        0,
        `calc(100vw / 2 - ${theme.spacing(420)} / 2)`,
        0
      ),
      [theme.breakpoints.down("md")]: {
        minHeight: `calc(${theme.spacing(270)} + ${
          theme.sizes.Header.mdHeight
        }px)`,
        paddingTop: theme.sizes.Header.mdHeight,
      },
      [theme.breakpoints.up("lg")]: {
        minHeight: theme.spacing(270),
        paddingTop: theme.sizes.Header.lgHeight,
      },
    },
    "& > section:nth-of-type(2n)": {
      background: "#F6FEFF",
    },
    "& > section:nth-of-type(5)": {
      [theme.breakpoints.up("md")]: {
        height: `calc(100vh - ${theme.spacing(54)})`,
        minHeight: `calc(${theme.spacing(270)} - ${theme.spacing(54)})`,
      },
    },
    "& > button": {
      position: "fixed",
      background: "white",
      minHeight: 0,
      width: 48,
      height: 48,
      border: `2px solid ${theme.palette.blue}`,
      [theme.breakpoints.up("lg")]: {
        width: 56,
        height: 56,
        border: `3px solid ${theme.palette.blue}`,
      },
      right: theme.spacing(11),
      bottom: theme.spacing(9),
      color: theme.palette.blue,
      "& svg": { width: "60%", height: "60%" },
    },
  },
  floatingCircle: {
    position: "absolute",
    borderRadius: "50%",
    zIndex: 1,
    transition:
      "10s top cubic-bezier(0.15,1,0,1), 10s left cubic-bezier(0.15,1,0,1)",
    "&#circle0": {
      width: "10vw",
      height: "10vw",
      background: `linear-gradient(135deg, #EFFAFC 30%, #D8F1FD 70%)`,
    },
    "&#circle1": {
      width: "6vw",
      height: "6vw",
      background: `linear-gradient(135deg, #F5E9F7 30%, #FBDDDD 70%)`,
    },
    "&#circle2": {
      width: "5vw",
      height: "5vw",
      background: `linear-gradient(135deg, #E0F7FA 30%, #FFEEA3 70%)`,
    },
    "&#circle3": {
      width: "1.5vw",
      height: "1.5vw",
      background: `#9575CD`,
    },
    "&#circle4": {
      width: "1.3vw",
      height: "1.3vw",
      background: `#DCE775`,
    },
    "&#circle5": {
      width: "0.75vw",
      height: "0.75vw",
      background: `#ffa69e`,
    },
  },
}));

const floatingCirclePositions = (sectionHeight) => [
  [
    { top: sectionHeight * 0.25 + "px", left: "4vw" },
    { top: sectionHeight * 1.27 + "px", left: "10vw" },
    { top: sectionHeight * 2.23 + "px", left: "3vw" },
    { top: sectionHeight * 3.55 + "px", left: "75vw" },
    { top: sectionHeight * 4.24 + "px", left: "6vw" },
  ],
  [
    { top: sectionHeight * 0.7 + "px", left: "35vw" },
    { top: sectionHeight * 1.87 + "px", left: "35vw" },
    { top: sectionHeight * 2.65 + "px", left: "31vw" },
    { top: sectionHeight * 3.45 + "px", left: "13vw" },
    { top: sectionHeight * 4.65 + "px", left: "-2vw" },
  ],
  [
    { top: sectionHeight * 0.15 + "px", left: "90vw" },
    { top: sectionHeight * 1.16 + "px", left: "82vw" },
    { top: sectionHeight * 2.14 + "px", left: "90vw" },
    { top: sectionHeight * 3.2 + "px", left: "87vw" },
    { top: sectionHeight * 4.4 + "px", left: "88vw" },
  ],
  [
    { top: sectionHeight * 0.85 + "px", left: "95vw" },
    { top: sectionHeight * 1.48 + "px", left: "90vw" },
    { top: sectionHeight * 2.85 + "px", left: "93vw" },
    { top: sectionHeight * 3.7 + "px", left: "8vw" },
    { top: sectionHeight * 4.95 + "px", left: "85vw" },
  ],
  [
    { top: sectionHeight * 0.8 + "px", left: "20vw" },
    { top: sectionHeight * 1.9 + "px", left: "60vw" },
    { top: sectionHeight * 2.9 + "px", left: "70vw" },
    { top: sectionHeight * 3.85 + "px", left: "90vw" },
    { top: sectionHeight * 4.52 + "px", left: "94vw" },
  ],
  [
    { top: sectionHeight * 0.25 + "px", left: "32vw" },
    { top: sectionHeight * 1.6 + "px", left: "5vw" },
    { top: sectionHeight * 2.2 + "px", left: "31vw" },
    { top: sectionHeight * 3.3 + "px", left: "12vw" },
    { top: sectionHeight * 4.5 + "px", left: "5vw" },
  ],
];

export const LandingWeb = () => {
  const classes = useStyles();
  const sectionRef = useRef(null);
  const [sectionIdx, setSectionIdx] = useState(0);
  const [sectionHeight, setSectionHeight] = useState(1080);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const handleResize = () => {
      const newSectionHeight =
        sectionRef.current &&
        getComputedStyle(sectionRef.current).height.replace("px", "");
      setSectionHeight(newSectionHeight);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const maxScrollY =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      let newSectionIndex;

      if (
        matches &&
        scrollY < maxScrollY &&
        scrollY > theme.sizes.Header.mdHeight + 2 * sectionHeight
      ) {
        newSectionIndex = 3;
      } else if (matches && scrollY === maxScrollY) {
        newSectionIndex = 4;
      } else {
        newSectionIndex = Math.round(scrollY / sectionHeight);
      }
      setSectionIdx(newSectionIndex);
    };
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sectionHeight, matches, theme.sizes.Header.mdHeight]);

  const handleClick = (newSectionIdx) => {
    const maxScrollY =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    if (newSectionIdx * sectionHeight > maxScrollY && newSectionIdx === 3) {
      window.scrollTo({
        top: maxScrollY - 1,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        top: newSectionIdx * sectionHeight,
        behavior: "smooth",
      });
    }
  };
  const handleGoToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <Header sectionIdx={sectionIdx} handleClick={handleClick} />
      <main className={classes.main}>
        {floatingCirclePositions(sectionHeight).map((position, idx) => (
          <div
            key={`circle${idx}`}
            className={classes.floatingCircle}
            id={`circle${idx}`}
            style={position[sectionIdx]}
          />
        ))}

        <section>
          <LandingHome />
        </section>
        <section ref={sectionRef}>
          <LandingCorpIntro />
        </section>
        <section>
          <LandingUser />
        </section>
        <section>
          <LandingManager />
        </section>
        <section>
          <LandingContact />
        </section>
        <Zoom in={sectionIdx > 0} unmountOnExit>
          <Fab className={classes.fab} onClick={handleGoToTop}>
            <ChevronUpIcon />
          </Fab>
        </Zoom>
      </main>

      <Footer onLogoClick={handleClick} />
    </>
  );
};
