import React, { useState } from "react";
import { makeStyles, Typography, Button } from "@material-ui/core";

import { ChevronRightIcon } from "./icons";
import { ContactDialog } from "./";
import {
  mobileFee,
  mobileFrame,
  mobileHome,
  mobileNotice,
  mobileSplash,
  mobileVehicle,
  monitorDashboard,
  monitorFrame,
} from "../assets";

const useStyles = makeStyles((theme) => ({
  "@keyframes home": {
    "0%": { top: "200%" },
    "5%": { top: "25%" },
    "43%": { top: "25%" },
    "50%": { top: "-200%" },
    "100%": { top: "-200%" },
  },
  "@keyframes notice": {
    "0%": { top: "200%" },
    "5%": { top: "0%" },
    "43%": { top: "0%" },
    "50%": { top: "-200%" },
    "100%": { top: "-200%" },
  },
  "@keyframes splash": {
    "0%": { top: "200%" },
    "5%": { top: "51%" },
    "43%": { top: "51%" },
    "50%": { top: "-200%" },
    "100%": { top: "-200%" },
  },
  "@keyframes fee": {
    "0%": { top: "200%" },
    "5%": { top: "10%" },
    "43%": { top: "10%" },
    "50%": { top: "-200%" },
    "100%": { top: "-200%" },
  },
  "@keyframes vehicle": {
    "0%": { top: "200%" },
    "5%": { top: "61%" },
    "43%": { top: "61%" },
    "50%": { top: "-200%" },
    "100%": { top: "-200%" },
  },
  "@keyframes monitor": {
    "0%": { opacity: 0 },
    "2%": { opacity: 1 },
    "43%": { opacity: 1 },
    "45%": { opacity: 0 },
    "100%": { opacity: 0 },
  },
  root: {
    zIndex: 1,
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: theme.spacing(0, 3),
    "& > #slogan": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      "& > h1": {
        fontSize: theme.spacing(17.5),
        fontWeight: 700,
        margin: theme.spacing(1, 0),
        "& > span": {
          color: theme.palette.text.blue,
        },
      },
      "& > h2": {
        fontSize: theme.spacing(8),
        fontWeight: 400,
        marginTop: theme.spacing(7),
      },
      "& > button": {
        width: theme.spacing(63),
        height: theme.spacing(19),
        marginTop: theme.spacing(12),
        marginBottom: theme.spacing(20),
        background: "linear-gradient(90deg, #2945FF 0%, #269AE7 100%)",
        borderRadius: theme.spacing(9.5),
        color: "white",
        fontSize: theme.spacing(7.5),
        fontWeight: 400,
        "& svg": {
          marginTop: theme.spacing(1),
          marginLeft: theme.spacing(-1),
          height: theme.spacing(11),
          width: theme.spacing(11),
        },
      },
    },
    "& > #demo": {
      position: "relative",
      "& > #circles": {
        position: "relative",
        width: theme.spacing(240),
        height: theme.spacing(240),
        "& > div": {
          borderRadius: "50%",
          position: "absolute",
          background: "linear-gradient(45deg, #4FC3F7, #69F0AE)",
        },
        "& > div:nth-child(1)": {
          width: "100%",
          height: "100%",
          opacity: 0.1,
        },
        "& > div:nth-child(2)": {
          top: "5%",
          left: "5%",
          width: "90%",
          height: "90%",
          opacity: 0.2,
        },
        "& > div:nth-child(3)": {
          top: "10%",
          left: "10%",
          width: "80%",
          height: "80%",
          opacity: 0.4,
        },
        "& > div:nth-child(4)": {
          top: "15%",
          left: "15%",
          width: "70%",
          height: "70%",
          opacity: 0.8,
        },
      },
      "& > #mobiles": {
        position: "absolute",
        top: 0,
        width: "100%",
        height: "100%",
        transform: "rotate(30deg)",
        "& > div": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          top: "200%",
          width: theme.spacing(65),
          height: theme.spacing(119),
          background: `no-repeat center / 100% 100% url(${mobileFrame})`,
          animation: "10s linear infinite",
          "& > div": {
            borderRadius: theme.spacing(5),
            marginTop: theme.spacing(-1),
            width: theme.spacing(57),
            height: theme.spacing(111),
          },
        },
        "& > div#home": {
          left: "5%",
          animationDelay: "0ms",
          animationName: "$home",
          "& > div": {
            background: `no-repeat center / 100% 100% url(${mobileHome})`,
          },
        },
        "& > div#notice": {
          left: "33%",
          animationDelay: "200ms",
          animationName: "$notice",
          "& > div": {
            background: `no-repeat center / 100% 100% url(${mobileNotice})`,
          },
        },
        "& > div#splash": {
          left: "33%",
          animationDelay: "400ms",
          animationName: "$splash",
          "& > div": {
            background: `no-repeat center / 100% 100% url(${mobileSplash})`,
          },
        },
        "& > div#fee": {
          left: "61%",
          animationDelay: "600ms",
          animationName: "$fee",
          "& > div": {
            background: `no-repeat center / 100% 100% url(${mobileFee})`,
          },
        },
        "& > div#vehicle": {
          left: "61%",
          animationDelay: "800ms",
          animationName: "$vehicle",
          "& > div": {
            background: `no-repeat center / 100% 100% url(${mobileVehicle})`,
          },
        },
      },
      "& > #monitor": {
        position: "absolute",
        top: "13%",
        width: "100%",
        height: "72%",
        background: `no-repeat center / 100% 100% url(${monitorFrame})`,
        animation: "10s linear infinite",
        animationDelay: "5600ms",
        opacity: 0,
        animationName: "$monitor",
        "& > div": {
          height: "84%",
          margin: theme.spacing(3),
          background: `no-repeat center / 100% 100% url(${monitorDashboard})`,
        },
      },
    },
  },
}));

export const LandingHome = () => {
  const classes = useStyles();
  const [contactDialogOpen, setContactDialogOpen] = useState(false);

  const handleContactDialogClick = () => {
    setContactDialogOpen(true);
  };
  const handleContactDialogClose = () => {
    setContactDialogOpen(false);
  };

  return (
    <div className={classes.root}>
      <div id="slogan">
        <Typography variant="h1">
          <span>똑똑</span>하고 <span>편리</span>한,
        </Typography>
        <Typography variant="h1">내 손 안의</Typography>
        <Typography variant="h1">우리집 스마트 주차장</Typography>
        <Typography variant="h2">파이파킹 스마트 주차관리 시스템</Typography>
        <Button endIcon={<ChevronRightIcon />} onClick={handleContactDialogClick}>
          서비스 문의
        </Button>
      </div>
      <div id="demo">
        <div id="circles">
          <div />
          <div />
          <div />
          <div />
        </div>
        <div id="mobiles">
          <div id="home">
            <div />
          </div>
          <div id="notice">
            <div />
          </div>
          <div id="splash">
            <div />
          </div>
          <div id="fee">
            <div />
          </div>
          <div id="vehicle">
            <div />
          </div>
        </div>
        <div id="monitor">
          <div />
        </div>
      </div>
      <ContactDialog open={contactDialogOpen} onClose={handleContactDialogClose} />
    </div>
  );
};
