import React, { useState } from "react";
import { makeStyles, Typography, Fab } from "@material-ui/core";

import {
  mobileFee,
  mobileFrame,
  mobileHome,
  mobileNotice,
  mobileVehicle,
} from "../assets";
import { ChevronLeftIcon, ChevronRightIcon } from "./icons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    zIndex: 1,
    position: "relative",
    "& > h1": {
      fontSize: theme.spacing(11),
      fontWeight: 700,
      textAlign: "center",
      "&:first-child": {
        marginTop: theme.spacing(15),
        marginBottom: theme.spacing(4),
      },
      "&:nth-child(2)": {
        marginBottom: theme.spacing(10),
      },
    },
    "& > #titleAndContent": {
      zIndex: 2,
      display: "flex",
      transition: "opacity 0.5s",
      "& > #title": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#00BCD4",
        height: theme.spacing(13),
        padding: theme.spacing(3, 5),
        borderRadius: theme.spacing(6.5),
        marginRight: theme.spacing(5),
        "& > h2": {
          fontSize: theme.spacing(6),
          fontWeight: 700,
          color: "white",
        },
      },
      "& > #content > p": { fontSize: theme.spacing(4.5) },
    },
    "& > #demo": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      marginTop: theme.spacing(-17.5),
      height: theme.spacing(200),
      "& > #circles": {
        position: "relative",
        width: theme.spacing(200),
        height: theme.spacing(200),
        "& > div": {
          borderRadius: "50%",
          position: "absolute",
          background: "linear-gradient(45deg, #4FC3F7, #69F0AE)",
        },
        "& > div:nth-child(1)": {
          width: "100%",
          height: "100%",
          opacity: 0.1,
        },
        "& > div:nth-child(2)": {
          top: "5%",
          left: "5%",
          width: "90%",
          height: "90%",
          opacity: 0.15,
        },
        "& > div:nth-child(3)": {
          top: "10%",
          left: "10%",
          width: "80%",
          height: "80%",
          opacity: 0.2,
        },
        "& > div:nth-child(4)": {
          top: "15%",
          left: "15%",
          width: "70%",
          height: "70%",
          opacity: 0.3,
        },
      },
      "& > #mobiles": {
        position: "absolute",
        overflowX: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        "& > div": {
          position: "absolute",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: theme.spacing(82.5),
          height: theme.spacing(150),
          background: `no-repeat center / 100% 100% url(${mobileFrame})`,

          "& > div": {
            borderRadius: theme.spacing(6),
            marginTop: theme.spacing(-1),
            marginLeft: theme.spacing(1),
            width: theme.spacing(73),
            height: theme.spacing(141),
          },
        },
        "& > div#home": {
          "& > div": {
            background: `no-repeat center / 100% 100% url(${mobileHome})`,
          },
        },
        "& > div#notice": {
          "& > div": {
            background: `no-repeat center / 100% 100% url(${mobileNotice})`,
          },
        },
        "& > div#fee": {
          "& > div": {
            background: `no-repeat center / 100% 100% url(${mobileFee})`,
          },
        },
        "& > div#vehicle": {
          "& > div": {
            background: `no-repeat center / 100% 100% url(${mobileVehicle})`,
          },
        },
      },
      "& > #fabs": {
        position: "absolute",
        display: "flex",
        justifyContent: "space-between",
        width: theme.spacing(180),
        "& > button": {
          color: "white",
          background: theme.palette.blue,
          minHeight: 32,
          minWidth: 32,
          width: theme.spacing(14),
          height: theme.spacing(14),
          "& svg": { width: "60%", height: "60%" },
        },
      },
    },
  },
}));

export const LandingUser = () => {
  const classes = useStyles();
  const [order, setOrder] = useState([
    "fee",
    "vehicle",
    "home",
    "notice",
    "fee",
  ]);
  const [isMoving, setIsMoving] = useState(false);

  const handleFabClick = (value) => () => {
    setIsMoving(value);
  };
  const handelTransitionEnd = () => {
    let newOrder = order;
    if (isMoving > 0) {
      newOrder.shift();
      newOrder.push(newOrder[0]);
    } else if (isMoving < 0) {
      newOrder.pop();
      newOrder.unshift(newOrder[newOrder.length - 1]);
    }
    setOrder(newOrder);
    setIsMoving(false);
  };

  let styles = [
    { transform: "scale(0.7) translate(-520%)", opacity: 0.7 },
    { transform: "scale(0.7) translate(-260%)", opacity: 0.7 },
    {},
    { transform: "scale(0.7) translate(260%)", opacity: 0.7 },
    { transform: "scale(0.7) translate(520%)", opacity: 0.7 },
  ];
  if (isMoving > 0) {
    styles = [
      {
        transition: ".5s",
        transform: "scale(0.7) translate(-780%)",
        opacity: 0.7,
      },
      {
        transition: ".5s",
        transform: "scale(0.7) translate(-520%)",
        opacity: 0.7,
      },
      {
        transition: ".5s",
        transform: "scale(0.7) translate(-260%)",
        opacity: 0.7,
      },
      { transition: ".5s" },
      {
        transition: ".5s",
        transform: "scale(0.7) translate(260%)",
        opacity: 0.7,
      },
    ];
  } else if (isMoving < 0) {
    styles = [
      {
        transition: ".5s",
        transform: "scale(0.7) translate(-260%)",
        opacity: 0.7,
      },
      { transition: ".5s" },
      {
        transition: ".5s",
        transform: "scale(0.7) translate(260%)",
        opacity: 0.7,
      },
      {
        transition: ".5s",
        transform: "scale(0.7) translate(520%)",
        opacity: 0.7,
      },
      {
        transition: ".5s",
        transform: "scale(0.7) translate(780%)",
        opacity: 0.7,
      },
    ];
  }

  let titleAndContent = {
    title: "Home",
    content1: "입출차 알림, 주차요금 조회, 등록 차량 관리",
    content2: "쉽고 편리한 내 손 안의 우리집 스마트 주차장",
  };
  switch (order[2]) {
    case "notice":
      titleAndContent.title = "입출차 알림";
      titleAndContent.content1 = "차량 입출차 시 내 휴대폰으로 알림을!";
      titleAndContent.content2 = "입출차 알림 내역 검색으로 더욱 편리하게";
      break;
    case "fee":
      titleAndContent.title = "주차요금 조회";
      titleAndContent.content1 = "실시간으로 확인 가능한 우리집 주차 요금";
      titleAndContent.content2 = "월별 요금 명세서 확인도 간편하게";
      break;
    case "vehicle":
      titleAndContent.title = "등록차량 관리";
      titleAndContent.content1 = "우리집 차량과 방문 차량을 미리 등록하면";
      titleAndContent.content2 = "입출차 시 별도의 절차 없이 빠르고 편리하게";
      break;
    default:
      break;
  }

  return (
    <div className={classes.root}>
      <Typography variant="h1">쉽고 편리한</Typography>
      <Typography variant="h1">사용자 어플리케이션</Typography>
      <div
        id="titleAndContent"
        style={isMoving ? { opacity: 0 } : { opacity: 1 }}
      >
        <div id="title">
          <Typography variant="h2">{titleAndContent.title}</Typography>
        </div>
        <div id="content">
          <Typography>{titleAndContent.content1}</Typography>
          <Typography>{titleAndContent.content2}</Typography>
        </div>
      </div>
      <div id="demo">
        <div id="circles">
          <div />
          <div />
          <div />
          <div />
        </div>
        <div id="mobiles">
          <div
            id={order[0]}
            style={styles[0]}
            onTransitionEnd={handelTransitionEnd}
          >
            <div />
          </div>
          <div id={order[1]} style={styles[1]}>
            <div />
          </div>
          <div id={order[2]} style={styles[2]}>
            <div />
          </div>
          <div id={order[3]} style={styles[3]}>
            <div />
          </div>
          <div id={order[4]} style={styles[4]}>
            <div />
          </div>
        </div>
        <div id="fabs">
          <Fab>
            <ChevronLeftIcon onClick={handleFabClick(-1)} />
          </Fab>
          <Fab>
            <ChevronRightIcon onClick={handleFabClick(1)} />
          </Fab>
        </div>
      </div>
    </div>
  );
};
