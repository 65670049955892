import React from "react";
import { Dialog, IconButton, makeStyles, Typography } from "@material-ui/core";

import { CloseIcon } from "./icons";
import { contactDialogIcon } from "../assets";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialog-paper": {
      width: 840,
      position: "relative",
      padding: "80px 80px 76px",
      margin: 0,
      maxWidth: "100%",
      [theme.breakpoints.down("xs")]: {
        width: 840 * 0.4,
        padding: `${80 * 0.4}px ${60 * 0.4}px ${76 * 0.4}px`,
      },
      [theme.breakpoints.only("sm")]: {
        width: 840 * 0.7,
        padding: `${80 * 0.7}px ${80 * 0.7}px ${76 * 0.7}px`,
      },
      [theme.breakpoints.only("md")]: {
        width: 840 * 0.8,
        padding: `${80 * 0.8}px ${80 * 0.8}px ${76 * 0.8}px`,
      },
      [theme.breakpoints.up("lg")]: {
        transform: "scale(0.9)",
      },
      [theme.breakpoints.up("xl")]: {
        transform: "scale(1)",
      },
      "& > button": {
        position: "absolute",
        top: 10,
        right: 10,
        [theme.breakpoints.down("xs")]: {
          top: 10 * 0.5,
          right: 10 * 0.5,
        },
        [theme.breakpoints.only("sm")]: {
          top: 10 * 0.7,
          right: 10 * 0.7,
        },
        [theme.breakpoints.only("md")]: {
          top: 10 * 0.8,
          right: 10 * 0.8,
        },
        "& svg": {
          color: "black",
          width: 48,
          height: 48,
          [theme.breakpoints.down("xs")]: {
            width: 48 * 0.5,
            height: 48 * 0.5,
          },
          [theme.breakpoints.only("sm")]: {
            width: 48 * 0.7,
            height: 48 * 0.7,
          },
          [theme.breakpoints.only("md")]: {
            width: 48 * 0.8,
            height: 48 * 0.8,
          },
        },
      },
      "& > .title": {
        display: "flex",
        alignItems: "center",
        marginBottom: 16,
        [theme.breakpoints.down("xs")]: {
          marginBottom: 16 * 0.5,
        },
        [theme.breakpoints.only("sm")]: {
          marginBottom: 16 * 0.7,
        },
        [theme.breakpoints.only("md")]: {
          marginBottom: 16 * 0.8,
        },
        "& > h1": {
          fontSize: 40,
          fontWeight: 700,
          [theme.breakpoints.down("xs")]: {
            fontSize: 40 * 0.5,
          },
          [theme.breakpoints.only("sm")]: {
            fontSize: 40 * 0.7,
          },
          [theme.breakpoints.only("md")]: {
            fontSize: 40 * 0.8,
          },
        },
        "& > img": {
          width: 50,
          height: 50,
          marginLeft: 16,
          [theme.breakpoints.down("xs")]: {
            width: 50 * 0.5,
            height: 50 * 0.5,
            marginLeft: 16 * 0.5,
          },
          [theme.breakpoints.only("sm")]: {
            width: 50 * 0.7,
            height: 50 * 0.7,
            marginLeft: 16 * 0.7,
          },
          [theme.breakpoints.only("md")]: {
            width: 50 * 0.8,
            height: 50 * 0.8,
            marginLeft: 16 * 0.8,
          },
        },
      },
      "& > .content": {
        marginBottom: 40,
        [theme.breakpoints.down("xs")]: {
          marginBottom: 40 * 0.5,
        },
        [theme.breakpoints.only("sm")]: {
          marginBottom: 40 * 0.7,
        },
        [theme.breakpoints.only("md")]: {
          marginBottom: 40 * 0.8,
        },
        "& > p": {
          fontSize: 24,
          [theme.breakpoints.down("xs")]: {
            fontSize: 24 * 0.5,
          },
          [theme.breakpoints.only("sm")]: {
            fontSize: 24 * 0.7,
          },
          [theme.breakpoints.only("md")]: {
            fontSize: 24 * 0.8,
          },
        },
      },
      "& > .contact": {
        display: "flex",
        marginBottom: 24,
        [theme.breakpoints.down("xs")]: {
          marginBottom: 24 * 0.5,
        },
        [theme.breakpoints.only("sm")]: {
          marginBottom: 24 * 0.7,
        },
        [theme.breakpoints.only("md")]: {
          marginBottom: 24 * 0.8,
        },
        "& > h2": {
          fontFamily: "Montserrat",
          fontSize: 30,
          fontWeight: 700,
          width: 128,
          marginTop: 6,
          [theme.breakpoints.down("xs")]: {
            fontSize: 30 * 0.5,
            width: 128 * 0.5,
            marginTop: 6 * 0.5,
          },
          [theme.breakpoints.only("sm")]: {
            fontSize: 30 * 0.7,
            width: 128 * 0.7,
            marginTop: 6 * 0.7,
          },
          [theme.breakpoints.only("md")]: {
            fontSize: 30 * 0.8,
            width: 128 * 0.8,
            marginTop: 6 * 0.8,
          },
        },
        "& > p": {
          fontFamily: "Montserrat",
          fontSize: 30,
          fontWeight: 500,
          borderBottom: `2px solid`,
          [theme.breakpoints.down("xs")]: {
            fontSize: 30 * 0.5,
            borderBottom: `${2 * 0.5}px solid`,
          },
          [theme.breakpoints.only("sm")]: {
            fontSize: 30 * 0.7,
            borderBottom: `${2 * 0.7}px solid`,
          },
          [theme.breakpoints.only("md")]: {
            fontSize: 30 * 0.8,
            borderBottom: `${2 * 0.8}px solid`,
          },
        },
      },
    },
  },
}));

export const ContactDialog = ({ open, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog
      onClose={onClose}
      open={open}
      className={classes.root}
      maxWidth={false}
    >
      <IconButton onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <div className="title">
        <Typography variant="h1">서비스 문의</Typography>
        <img src={contactDialogIcon} alt="contactDialogIcon" />
      </div>
      <div className="content">
        <Typography>서비스 상담 및 시공 견적 문의가 필요하신가요?</Typography>
        <Typography>
          아래 연락처로 문의 주시면 정성껏 답변해 드리겠습니다.
        </Typography>
      </div>
      <div className="contact">
        <Typography variant="h2">TEL .</Typography>
        <Typography>02-2220-0392</Typography>
      </div>
      <div className="contact">
        <Typography variant="h2">EMAIL .</Typography>
        <Typography>contact@paiparking.com</Typography>
      </div>
    </Dialog>
  );
};
