import React, { useEffect, useState } from "react";
import { makeStyles, Typography, Tabs, Tab } from "@material-ui/core";

import {
  monitorFrame,
  monitorDashboard,
  monitorParkingSpace,
  monitorRealtimeVideo,
  monitorVideoRecord,
  monitorRealtimeInOut,
  monitorInOutRecord,
  monitorVehicle,
  monitorParkingFee,
} from "../assets";

const tabs = [
  { label: "대시보드", className: "monitorDashboard", img: monitorDashboard },
  {
    label: "주차 공간",
    className: "monitorParkingSpace",
    img: monitorParkingSpace,
  },
  {
    label: "실시간 영상",
    className: "monitorRealtimeVideo",
    img: monitorRealtimeVideo,
  },
  {
    label: "영상 기록",
    className: "monitorVideoRecord",
    img: monitorVideoRecord,
  },
  {
    label: "실시간 입출차",
    className: "monitorRealtimeInOut",
    img: monitorRealtimeInOut,
  },
  {
    label: "입출차 기록",
    className: "monitorInOutRecord",
    img: monitorInOutRecord,
  },
  {
    label: "차량 등록 및 관리",
    className: "monitorVehicle",
    img: monitorVehicle,
  },
  {
    label: "주차 요금",
    className: "monitorParkingFee",
    img: monitorParkingFee,
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    zIndex: 1,
    position: "relative",
    "& > h1": {
      fontSize: theme.spacing(11),
      fontWeight: 700,
      textAlign: "center",
      "&:first-child": {
        marginTop: theme.spacing(15),
        marginBottom: theme.spacing(4),
      },
      "&:nth-child(2)": {
        marginBottom: theme.spacing(10),
      },
    },
    "& > .tabs": {
      width: theme.spacing(276),
      marginBottom: theme.spacing(15),
      minHeight: 0,
      "& .MuiTabScrollButton-root": {
        color: "black",
        width: theme.spacing(13),
        height: theme.spacing(13),
        "& svg": {
          width: theme.spacing(12.5),
          height: theme.spacing(12.5),
        },
        "&.Mui-disabled": {
          color: theme.palette.grey[300],
          opacity: 1,
        },
      },
      "& .MuiTab-root": {
        color: theme.palette.grey[400],
        minWidth: 0,
        minHeight: 0,
        height: theme.spacing(13),
        padding: theme.spacing(3, 5),
        borderRadius: theme.spacing(6.5),
        fontSize: theme.spacing(6),
        fontWeight: 400,
        "&.Mui-selected": {
          color: "white",
          fontWeight: 700,
          background: "#00BCD4",
        },
        "& > span": {
          marginTop: theme.spacing(-0.75),
        },
      },
      "& .MuiTabs-indicator": {
        display: "none",
      },
    },
    "& > .tabPanel": {
      width: theme.spacing(200),
      height: theme.spacing(145),
      marginBottom: theme.spacing(20),
      background: `no-repeat center / 100% 100% url(${monitorFrame})`,
      "& > div": {
        height: theme.spacing(120),
        margin: theme.spacing(3),
        transition: "background 0.5s ease-in-out",
      },
      ...tabs.reduce(
        (prev, tab) => ({
          ...prev,
          [`& > div.${tab.className}`]: {
            background: `no-repeat center / 100% 100% url(${tab.img})`,
          },
        }),
        {}
      ),
    },
  },
}));

export const LandingManager = () => {
  const classes = useStyles();
  const [tabIdx, setTabIdx] = useState(0);

  useEffect(() => {
    let timer;
    clearInterval(timer);
    timer = setInterval(() => {
      let newTabIdx = tabIdx + 1;
      if (newTabIdx > tabs.length - 1) newTabIdx = 0;

      setTabIdx(newTabIdx);
    }, 3000);
    return () => {
      clearInterval(timer);
    };
  }, [tabIdx]);

  const handleTabClick = (e, idx) => {
    setTabIdx(idx);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h1">체계적이고 효율적인</Typography>
      <Typography variant="h1">웹 기반 주차관제 시스템</Typography>
      <Tabs
        className="tabs"
        value={tabIdx}
        onChange={handleTabClick}
        variant="scrollable"
        scrollButtons="on"
      >
        {tabs.map((tab, idx) => (
          <Tab
            key={tab.label}
            className={idx === tabIdx ? "active" : ""}
            label={tab.label}
          />
        ))}
      </Tabs>
      <div className="tabPanel">
        <div className={tabs[tabIdx].className} />
      </div>
    </div>
  );
};
